import { ADD_NOTE, POST_ERROR, CHARGE_BACK } from "../actions/types";

const initialState = {

  post: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case ADD_NOTE:
      return {
        ...state,
        post: { ...state.post, comments: payload },
        loading: false,
      };
  
        case CHARGE_BACK:
            return {
              ...state,
              post: { ...state.post, comments: payload },
              loading: false,
            };

    default:
      return state;
  }
}
