import { combineReducers } from 'redux'
import alert from './alert'
import auth from './auth';
import post from './post'
import subscription from './subscription';
import chargeback from './chargeback';

export default combineReducers({
    alert,
    auth,
    post,
    subscription,
    chargeback,
})