import { ADD_SUBS, SUBS_ERROR } from "../actions/types";

const initialState = {
  subscription: null, // Add this property
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUBS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case ADD_SUBS:
      return {
        ...state,
        subscription: payload,
        loading: false,
      };

    default:
      return state;
  }
}

