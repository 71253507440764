export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REMOVE_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'REGISTER_SUCCESS';
export const LOGIN_FAIL = 'REMOVE_FAIL';
export const LOGOUT = 'LOGOUT';
export const POST_ERROR = 'POST_ERROR';
export const ADD_POST = 'ADD_POST';
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_SUBS = 'ADD_SUBS';
export const SUBS_ERROR = 'SUBS_ERROR';
export const CHARGE_BACK = 'CHARGE_BACK';
export const CHARGE_BACK_ERROR = 'CHARGE_BACK_ERROR';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const CHANGE_STATUS_ERROR = 'CHANGE_STATUS_ERROR';
export const FREEZING = 'FREEZING';
export const FREEZING_ERROR = 'FREEZING_ERROR';
export const ADD_ASSIGN = 'ADD_ASSIGN';
export const ADD_ASSIGN_ERROR = 'ADD_ASSIGN_ERROR';

